.ant-table-wrapper .ant-table-thead .ant-table-cell {
  @apply bg-white;
}

.ant-table-wrapper .ant-table-thead th.ant-table-column-sort {
  @apply bg-white;
}

.ant-table-wrapper .ant-table-tbody td.ant-table-column-sort {
  @apply bg-white;
}

.ant-table-wrapper
  .ant-table-thead
  > tr
  > th:not(:last-child):not(.ant-table-selection-column):not(
    .ant-table-row-expand-icon-cell
  ):not([colspan])::before,
:where(.css-dev-only-do-not-override-1wazalj).ant-table-wrapper
  .ant-table-thead
  > tr
  > td:not(:last-child):not(.ant-table-selection-column):not(
    .ant-table-row-expand-icon-cell
  ):not([colspan])::before {
  width: 0px;
}

.ant-table-wrapper .ant-table-thead .ant-table-cell {
  pointer-events: none;
  @media only screen and (min-width: 640px) {
    pointer-events: auto;
  }
}

.ant-table-wrapper .ant-table-column-sorter {
  @apply hidden;
}

.ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-title-content > a {
  @apply text-primary;
}

.ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-item-divider {
  @apply mx-2;
}

.ant-pagination .ant-pagination-item-active {
  @apply border-[#C3E600] bg-[#C3E600] rounded-[8px] hover:border-[#C3E600];
}

.ant-pagination .ant-pagination-item-active a {
  @apply text-primary hover:text-secondary;
}

.ant-table-wrapper .ant-table-thead > tr > th,
.ant-table-wrapper .ant-table-tbody > tr > th,
.ant-table-wrapper .ant-table-tbody > tr > td,
.ant-table-wrapper tfoot > tr > th,
.ant-table-wrapper tfoot > tr > td {
  @apply px-1;
}

.ant-pagination .ant-pagination-disabled .ant-pagination-item-link,
.ant-pagination .ant-pagination-disabled:hover .ant-pagination-item-link {
  @apply flex items-center justify-center;
}

.ant-pagination .ant-pagination-prev .ant-pagination-item-link,
.ant-pagination .ant-pagination-next .ant-pagination-item-link {
  @apply flex items-center justify-center;
}

.ant-empty-normal .ant-empty-description {
  @apply font-manrope;
}

.ant-spin-nested-loading > div > .ant-spin {
  max-height: 100%;
}

.ant-input {
  @apply border-secondary rounded-[4px] border-opacity-50 font-manrope text-[16px];
}
