.react-tel-input .country-list .country-name {
  @apply font-manrope text-primary;
}

.react-tel-input .country-list .country .dial-code {
  @apply font-manrope text-secondary;
}

.logo {
  font-family: "Manrope";
  display: flex;
  align-items: center;
  gap: 6px;
  text-decoration: none;
  color: #23262f;
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;
  letter-spacing: -0.02em;

  @media screen and (max-width: 575px) {
    font-weight: 500;
    font-size: 20px;
    line-height: 32px;
  }

  &__img {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 41px;

    @media screen and (max-width: 575px) {
      width: 33px;
    }

    img {
      width: 100%;
      height: 100%;
    }
  }

  &__text {
    display: block;
    flex: 1;
  }
}

.invoice_item-text--left {
  color: grey;
}

.pie-chart-tooltip {
  border-radius: 0.25rem;
  background: #fafafa;
  color: #686666;
  padding: 1rem;
  text-align: center;
}

.chart {
  width: 100%;

  &__wrapper {
    width: 100%;
    max-width: 1014px;
  }
}

.chart-custom {
  margin-right: 0px;
}
.title-block {
  margin-bottom: 16px;
  font-size: 29px;
  gap: 16px;
  color: var(--text-main-color);
}
.title-actions {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  gap: 16px;
  margin-left: auto;
}

.button {
  min-width: 80px;
  height: 42px;
  padding: 0 16px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background: var(--main-color);
  gap: 12px;
  color: var(--text-main-color);
  border: none;
  border-radius: 10px;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.6;
  -webkit-transition: 0.2s;
  display: inline-flex;
  transition: 0.2s;
}

.button--type3 {
  color: var(--text-color-main);
  background: #fff;
  border: 1px solid #dadada;
}
.button--gap30 {
  gap: 30px;
}

.table-filter {
  margin-bottom: 30px;
  display: -ms-grid;
  display: grid;
  gap: 8px;
}

.table-filter--flex-type {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.input {
  position: relative;
  width: 100%;
  margin-bottom: 20px;
}
.input {
  margin-bottom: 16px;
}
.table-filter > * {
  margin: 0;
}
.table-filter__item {
  width: 160px;
}

.table-filter--flex-type > .input {
  width: 160px;
}
.input__name {
  display: block;
  margin-bottom: 8px;
  font-size: 14px;
  font-weight: 500;
}
.input__name {
  margin-bottom: 4px;
  font-weight: 400;
  font-size: 12px;
  color: var(--text-main-color2);
}
.input__name--type2 {
  font-size: 16px;
  color: #bcbead;
}

.select {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-line-pack: center;
  align-content: center;
  height: 44px;
  border-radius: 5px;
  border: 1px solid var(--ui-input-border-color);
  background: #fff;
  z-index: 3;
}
.select {
  height: 42px;
  border: 1px solid var(--border-main-color);
  border-radius: 10px;
}
.select--type2 {
  background: #ededed;
}
.select__current {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  width: 100%;
  padding: 0 15px;
  font-size: 14px;
}
.select__current {
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  gap: 8px;
  padding: 0 12px;
  font-size: 16px;
  font-weight: 400;
  line-height: 1;
  color: var(--text-main-color);
}
.select--type2 .select__current {
  font-size: 14px;
}
.select__current-text {
  margin-right: auto;
}
.select__current-arrow {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 8px;
  flex: 0 0 8px;
  max-width: 8px;
  margin-left: 10px;
}
.select__current-arrow {
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  margin-left: 0;
  min-width: 8px;
}

.select__drop {
  display: block;
  position: absolute;
  left: 0;
  top: calc(100% + 10px);
  width: 100%;
  padding: 5px;
  background: #ffffff;
  border: 1px solid var(--ui-select-border);
  border-radius: 4px;
  z-index: 1;
}
.select__drop {
  padding: 0;
  right: -1px;
  left: 0;
  min-width: 100%;
  border: none;
  -webkit-box-shadow: 0px 4px 45px 0px rgba(0, 0, 0, 0.25);
  box-shadow: 0px 4px 45px 0px rgba(0, 0, 0, 0.25);
  border-radius: 12px;
  background: #ffffff;
}

.select__drop-scroll {
  max-height: 180px;
  overflow: auto;
  scrollbar-color: #b6b6b6 transparent;
  scrollbar-width: thin;
}
.select__drop-scroll {
  max-height: 2194px;
}
ol,
ul {
  list-style: none;
}
.select__drop ul > li {
  padding: 0 16px;
  -webkit-transition: all 0.15s ease;
  transition: all 0.15s ease;
}
.select__drop ul > li > a,
.select__drop ul > li > button {
  display: block;
  width: 100%;
  padding: 10px;
  border-radius: 4px;
  color: #000;
  font-size: 14px;
  -webkit-transition: all 0.15s ease;
  transition: all 0.15s ease;
  text-align: left;
}
.select__drop ul > li > button {
  padding: 12px 0;
  font-weight: 500;
  font-size: 14px;
  color: var(--text-main-color2);
  border-radius: 0;
  -webkit-transition: all 0.15s ease;
  transition: all 0.15s ease;
}
.select__drop ul > li:first-child > button {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}
.table-footer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  padding-top: 20px;
}

.button--auto-width {
  width: auto;
  min-width: unset;
  padding: 0 8px;
}

.button--height-30 {
  height: 30px;
  border-radius: 15px;
  font-size: 12px;
}
.button--type4 {
  background-color: #fff;
  border: 1px solid black;
  color: black;
}

.rs-input-group.rs-input-group-inside {
  height: 100%;
}

div[data-testid=picker-popup] {
  transform: translateX(-24%);
}