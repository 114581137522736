@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    -webkit-tap-highlight-color: transparent;
  }
  html,
  body {
    @apply h-full font-body antialiased;
  }
  .order-list-enable ol {
    @apply list-decimal space-y-1.5 ltr:pl-4 rtl:pr-4 ltr:lg:pl-8 rtl:lg:pr-8;
  }
  button {
    @apply focus:outline-none;
  }
  /* custom scrollbar */
  ::-webkit-scrollbar {
    @apply h-1 w-1;
  }
  ::-webkit-scrollbar-thumb {
    @apply bg-dark-900;
  }
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.4);
  }
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.checkbox-component + span {
  @apply h-5 w-5 cursor-pointer rounded border border-light-500 ring-[0.5px] ring-light-500 dark:border-dark-600 dark:ring-dark-600;
}
.checkbox-component:checked + span {
  @apply border-active bg-active ring-active;
}
.checkbox-component:checked + span::before {
  /*
    @apply absolute inset-auto mt-[5px] ml-0.5 flex h-2 w-3 border-2 border-t-0 border-r-0 border-light content-[''];
    */
  @apply inset-auto mt-[5px] ml-0.5 flex h-2 w-3 border-2 border-t-0 border-r-0 border-light content-[''];
  transform: rotate(-45deg) translate(2px, -1px);
}
